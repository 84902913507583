<template>
  <div>
    {{ slots }}
  </div>
</template>

<script>
export default {
  name: 'TherapySlot',
  props: {
    slots: {

    },
  },
};
</script>

<style scoped>

</style>
