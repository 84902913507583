<template>
  <div>
    <el-button type="primary" @click="addChamber">Add Chamber</el-button>
    <div v-for="(item, index) in workplaces" :key="index">
      <br>
      <el-card class="box-card" shadow="never">
        <template #header>
          <div class="card-header d-flex align-items-center justify-content-between">
            <div>
              <h3>{{ item.institute_name }} {{ item.room_no ? 'Room No. # '+item.room_no : ''}}</h3>
              <p>{{ item.address }}</p>
            </div>
            <el-button class="button" @click="edit(item)" type="primary">Edit</el-button>
          </div>
        </template>
        <el-table :data="item.working_schedules" style="width: 100%">
          <el-table-column prop="day" label="Day" width="100"/>
          <el-table-column prop="start_time" label="Start time" width="100"/>
          <el-table-column prop="end_time" label="Close time" width="100"/>
          <el-table-column prop="time_per_slot" label="Time per slot" width="200">
            <template #default="scope">
              {{ scope.row.time_per_slot }} {{ scope.row.time_per_slot_unit }}
            </template>
          </el-table-column>
          <el-table-column prop="slots" label="Slots">
            <template #default="scope">

              <el-badge class="item m-1" v-for="(slot, index) in scope.row.slots"
                        :key="index">
                <el-button :type="slot.status == 2 ? 'danger' : ''">{{ slot.slot_time }}</el-button>
              </el-badge>
              <!--             <span class="mx-2 my-2" :class="slot.status == 2 ? 'badge badge-danger' : ''"-->
              <!--                   >{{ slot.slot_time }}  </span>-->
            </template>
          </el-table-column>
        </el-table>
      </el-card>
    </div>
    <el-dialog v-model="dialogFormVisible" :title="`${add_chamber ? 'Add' : 'Update'} Chamber`">
      <el-form
          label-position="top"
          label-width="100px"
          :model="form"
      >
        <el-row :gutter="20" class="justify-content-center">
          <el-col :span="24" width="100">
            <el-form-item label="Room Name">
              <el-input v-model="form.room_no"/>
            </el-form-item>
          </el-col>
          <el-col :span="24" width="100">
            <el-form-item label="Time Slot">
              <el-input v-model="form.time_slot"/>
            </el-form-item>
          </el-col>
          <el-col :span="24">

            <el-button-group class="ml-4 w-100">
              <el-button type="primary" v-for="(btn, idx) in weeks"
                         :key="idx"
                         @click="clickDay(btn.caption)">
                {{ btn.caption }}
              </el-button>
            </el-button-group>
          </el-col>
          <el-col :span="24">
            <div v-for="(schedule, index) in schedules" :key="index">
              <br>
              <el-card class="box-card" shadow="never">
                <template #header>
                  <div class="card-header">
                    <div class="d-flex justify-content-between align-items-center">
                      <div>{{ schedule.day }}</div>
                      <div>
                        <el-button type="success" v-if="schedule.slots.length < 3" icon="Plus" circle
                                   @click="addSlot(schedule)"/>
                        <el-button type="danger" v-else-if="schedule.slots.length == 1" icon="CloseBold" circle
                                   @click="addSlot(schedule)"/>

                      </div>
                    </div>
                  </div>
                </template>

                <div class="text item" v-for="(slot, slot_key) in schedule.slots" :key="slot_key">
                  <el-row :gutter="20" class="justify-content-center">
                    <el-col :span="10">
                      <el-form-item label="Start time">
                        <el-input type="time" v-model="slot.start_time"/>
                      </el-form-item>
                    </el-col>
                    <el-col :span="10">
                      <el-form-item label="End time">
                        <el-input type="time" v-model="slot.end_time"/>
                      </el-form-item>
                    </el-col>
                    <el-col :span="4">
                      <el-button class="mt-30" type="danger" @click="removeItem(schedule,slot_key)">
                        <el-icon>
                          <CloseBold/>
                        </el-icon>
                      </el-button>
                    </el-col>
                  </el-row>
                </div>

              </el-card>
            </div>
          </el-col>
        </el-row>
      </el-form>
      <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogFormVisible = false">Cancel</el-button>
        <el-button v-if="add_chamber" type="primary" @click="addSubmitForm">
          Confirm
        </el-button>
        <el-button v-else type="primary" @click="submitForm">
          Confirm
        </el-button>
      </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'VisitChamber',
  props: {
    workplaces: {},
    doctor: {},
  },
  data() {
    return {
      dialogFormVisible: false,
      form: {
        time_slot: '',
        institute_id: '',
        chamber_id: '',
        room_no: '',
      },
      weeks: [
        {
          caption: 'sunday',
          state: false,
        },
        {
          caption: 'monday',
          state: false,
        },
        {
          caption: 'tuesday',
          state: false,
        },
        {
          caption: 'wednesday',
          state: false,
        },
        {
          caption: 'thursday',
          state: false,
        },
        {
          caption: 'friday',
          state: false,
        },
        {
          caption: 'saturday',
          state: false,
        },
      ],
      schedules: [],
      openChamberDialog: false,
      rooms: [
        {
          value: 'Room-01',
          label: 'Room-01',
        },
        {
          value: 'Room-02',
          label: 'Room-02',
        },
        {
          value: 'Room-03',
          label: 'Room-03',
        },
        {
          value: 'Room-04',
          label: 'Room-04',
        },
        {
          value: 'Room-05',
          label: 'Room-05',
        },
        {
          value: 'Room-06',
          label: 'Room-06',
        },
      ],
      institute_form: {
        name: '',
        parent_id: '',
      },
      add_chamber: false,
    };
  },
  methods: {
    edit(item) {
      const first = item.working_schedules.slice(0, 1)
        .shift();
      this.form.time_slot = first ? first.time_per_slot : '';
      this.form.chamber_id = item.id;
      this.form.institute_id = item.institute_id;
      for (const working_schedule of item.working_schedules) {
        const exists_day = this.schedules.find((exitem) => exitem.day == working_schedule.day);
        if (!exists_day) {
          this.schedules.push({
            day: working_schedule.day,
            slots: [
              {
                start_time: working_schedule.start_time,
                end_time: working_schedule.end_time,
              },
            ],
          });
        } else {
          const schIndex = this.schedules.findIndex((schdule) => schdule.day == working_schedule.day);
          this.schedules[schIndex].slots.push({
            start_time: working_schedule.start_time,
            end_time: working_schedule.end_time,
          });
        }
      }
      this.dialogFormVisible = true;
    },
    submitForm() {
      const form = new FormData();
      this.schedules.map((item) => {
        form.append(`start_time[${item.day}]`, item.slots.map((u) => u.start_time)
          .join(','));
        form.append(`end_time[${item.day}]`, item.slots.map((u) => u.end_time)
          .join(','));
      });

      form.append('slot', this.makeStringCommaSeparate(this.schedules));
      form.append('working_day', this.workingDayCommaSeparate(this.schedules));

      form.append('type', 'private');
      form.append('room_no', this.form.room_no);
      form.append('institute_id', this.form.institute_id);
      form.append('_method', 'put');
      form.append('platform', 'occ-web');
      if (this.add_chamber) {
        form.append('room_no', this.form.room_no);
      }

      this.$nextTick(() => {
        axios
          .post(`/api/v1/doctor/chamber/update/${this.form.chamber_id}?doctor_id=${this.$route.params.id}`, form)
          .then((response) => {
            if (response.data.status_code == 400) {
              this.$notify({
                title: 'Failed',
                message: 'Update Failed',
                type: 'danger',
                duration: 2000,
              });
            } else {
              this.dialogFormVisible = false;
              this.$notify({
                title: 'Success',
                message: 'Successfully Updated',
                type: 'success',
                duration: 2000,
              });
              this.$emit('visitSlotUpdated', response.data.data);
            }
          });
      });
    },
    addSubmitForm() {
      const form = new FormData();
      this.schedules.map((item) => {
        form.append(`chamber[0][start_time][${item.day}]`, item.slots.map((u) => u.start_time)
          .join(','));
        form.append(`chamber[0][end_time][${item.day}]`, item.slots.map((u) => u.end_time)
          .join(','));
      });
      form.append('chamber[0][slot]', this.makeStringCommaSeparate(this.schedules));
      form.append('chamber[0][working_day]', this.workingDayCommaSeparate(this.schedules));
      form.append('chamber[0][type]', 'private');
      form.append('platform', 'occ-web');
      form.append('chamber[0][room_no]', this.form.room_no);

      this.$nextTick(() => {
        axios
          .post(`/api/v1/doctor/chamber/add?doctor_id=${this.$route.params.id}`, form)
          .then((response) => {
            if (response.data.status_code == 400) {
              this.$notify({
                title: 'Failed',
                message: 'Update Failed',
                type: 'danger',
                duration: 2000,
              });
            } else {
              this.dialogFormVisible = false;
              this.$notify({
                title: 'Success',
                message: 'Successfully Updated',
                type: 'success',
                duration: 2000,
              });
              this.$emit('visitSlotUpdated', response.data.data);
            }
          });
      });
    },
    makeStringCommaSeparate(days) {
      let dayStr = '';
      for (let i = 0; i < days.length; i++) {
        dayStr += this.form.time_slot;
        if (i + 1 < days.length) {
          dayStr += ',';
        }
      }
      return dayStr;
    },
    workingDayCommaSeparate(days) {
      const working_day = days.map((u) => u.day)
        .join(',');
      return working_day;
    },
    clickDay(day) {
      const exists_day = this.schedules.find((item) => item.day == day);
      if (!exists_day) {
        this.schedules.push({
          day,
          slots: [
            {
              start_time: '',
              end_time: '',
            },
          ],
        });
      }
    },
    addSlot(schedule) {
      if (schedule.slots.length < 3) {
        schedule.slots.push({
          start_time: '',
          end_time: '',
        });
      }
    },
    removeItem(schedule, index) {
      schedule.slots.splice(index, 1);
    },
    addChamber() {
      this.dialogFormVisible = true;
      this.add_chamber = true;
    },
  },
  watch: {
    dialogFormVisible(newValue, oldValue) {
      if (!newValue) {
        this.schedules = [];
        this.form = {};
        this.add_chamber = false;
      }
    },
  },
};
</script>

<style scoped>
h3, p {
  margin: 0;
  padding: 0;
}

.m-1 {
  margin: 2px;
}
</style>
