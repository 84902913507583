import axios from 'axios';

export const UsersMixin = {
  data() {
    return {
      doctors: [],
      patients: [],
    };
  },

  methods: {
    getDcotors() {
      this.loading = true;
      axios.post('/api/v1/doctor/connection/user/list?platform=backoffice')
        .then((response) => {
          this.doctors = response.data.data;
        });
    },
    getPatients() {
      axios.get('/api/v1/patient/subordinate/list')
        .then((response) => {
          this.patients = response.data.data;
        });
    },

  },
};
