<template>
  <el-card>
    <div>
      <el-tabs v-model="activeName" v-loading="loading" class="demo-tabs" @tab-click="handleClick">
        <el-tab-pane label="Doctor Details" name="doctor_details">
          <DoctorDetailsComponent :doctor_details="doctor"/>
        </el-tab-pane>
        <el-tab-pane label="Doctor Regular Chamber" name="doctor_regular_chamber">
          <VisitChamber :doctor="doctor" :workplaces="workplaces" @visitSlotUpdated="visitSlotUpdated"/>
        </el-tab-pane>
        <el-tab-pane label="Doctor Therapy Chamber" name="doctor_therapy_chamber">
          Coming soon
          <TherapySlot slots="hi"/>
        </el-tab-pane>
        <el-tab-pane label="Appointment" name="appointment">
          <div>
            <Appointment :doctor="doctor"/>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </el-card>
</template>

<script>
import axios from 'axios';
import VisitChamber from './VisitChamber';
import Appointment from '../appointments/Appointment';
import DoctorDetailsComponent from '../../components/doctor-component/DoctorDetailsComponent';
import TherapySlot from '../../components/doctor-component/TherapySlot';

export default {
  name: 'DoctorDetails',
  components: {
    VisitChamber,
    Appointment,
    DoctorDetailsComponent,
    TherapySlot,
  },
  data() {
    return {
      activeName: this.$route.query.appointment ? 'appointment' : 'doctor_details',
      loading: false,
      doctor_info: {},
      workplaces: [],
      therapy_slots: {},
      doctor_chamber_is_click: 0,
      doctor: {},
    };
  },
  created() {
    this.profile();
  },
  methods: {
    handleClick(obj, e) {
      if (obj.paneName == 'doctor_regular_chamber') {
        this.getChamberInfo();
      }
    },
    async profile() {
      this.loading = true;
      await axios.get(`/api/v1/user/profile/details/${this.$route.params.id}&platform=backoffice`)
        .then((res) => {
          this.doctor = res.data.data;
          this.doctor_info = res.data.data.doctor_information;
          this.loading = false;
        });
    },
    getChamberInfo() {
      this.loading = true;
      axios
        .get(`/api/v1/doctor/chamber/list?doctor_id=${this.$route.params.id}&show_all=1`)
        .then((res) => {
          this.workplaces = res.data.data;
          this.loading = false;
        });
    },
    visitSlotUpdated() {
      this.getChamberInfo();
    },
  },
  title: 'Doctor',
};
</script>

<style scoped>

</style>
