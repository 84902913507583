<template>
  <div v-loading.fullscreen.lock="loading">
    <el-tabs v-model="appointActiveName" @tab-click="handleClick">
      <el-tab-pane label="Visit Appointment" name="first">
        <div>
          <el-row :gutter="1">
            <el-col :span="24">
              <div class="width-100">
                <el-date-picker
                    class="app_date_picker"
                    popper-class="width-100"
                    v-model="form.date_value"
                    type="date"
                    placeholder="Pick a day"
                    :shortcuts="shortcuts"
                    :size="size"
                    format="YYYY-MM-DD"
                    value-format="YYYY-MM-DD"
                    @change="clickDate"
                    :fit-input-width="true"
                />
              </div>
            </el-col>
          </el-row>
          <br>
          <div v-if="chambers && form.date_value">
            <ChamberWithSlots @workPlaceId="workPlaceId" :chambers="chambers" v-model="form.slot_value"/>
          </div>
          <br>
          <div v-if="form.date_value">
            <el-row justify="center">
              <el-col :span="16">
                <div class="d-flex justify-content-between align-items-center">
                  <div class="w-100">
                    <el-select class="w-100" v-model="form.patient_id" filterable placeholder="Select">
                      <el-option
                          v-for="item in patients"
                          :key="item.id"
                          :label="item.fullname"
                          :value="item.id"
                      />
                    </el-select>
                  </div>
                  <div class="mx-5">
                    <div>
                      <el-button type="primary" @click="addNewPatient">Create New Patient</el-button>
                    </div>
                  </div>
                </div>
              </el-col>
            </el-row>
            <br>
            <AppointDetails :doctor="doctor" :date_value="form.date_value" :slot="form.slot_value"/>
            <br>
            <div class="text-center" v-if="form.patient_id">
              <el-button @click="confirmAppointment" type="primary" round>Confirm Appointment</el-button>
            </div>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="Virtual Appointment" name="second">Virtual Appointment</el-tab-pane>
    </el-tabs>
    <div>
      <AddPatient @modalClosed="is_change_modal_open=false" v-if="is_change_modal_open"/>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import AddPatient from '../../components/modal/AddPatientModal';
import { UsersMixin } from '../../mixins/UsersMixin';
import ChamberWithSlots from '../../components/ChamberWithSlots';
import AppointDetails from '../../components/AppointmentDetails';

export default {
  name: 'Appointment',
  props: {
    doctor: {},
  },
  components: {
    AddPatient,
    ChamberWithSlots,
    AppointDetails,
  },
  mixins: [UsersMixin],
  data() {
    return {
      appointActiveName: 'first',
      form: {
        date_value: '',
        service_type: '',
        patient_id: '',
        slot_value: '',
        workplace_id: '',
      },

      shortcuts: [
        {
          text: 'Today',
          value: new Date(),
        },
        {
          text: 'Yesterday',
          value: () => {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24);
            return date;
          },
        },
        {
          text: 'A week ago',
          value: () => {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
            return date;
          },
        },
      ],
      size: 'large',
      is_change_modal_open: false,
      loading: false,
      chambers: [],

    };
  },
  created() {
    this.getPatients();
  },
  methods: {
    addNewPatient() {
      this.is_change_modal_open = true;
    },
    workPlaceId(id) {
      this.form.workplace_id = id;
    },
    clickDate() {
      this.loading = true;
      this.service_type = 'visit';
      axios
        .get(`/api/v1/doctor/chamber/list?schedule_date=${this.form.date_value}&doctor_id=${this.doctor.id}`)
        .then((res) => {
          this.chambers = res.data.data;
          this.loading = false;
        });
    },
    handleClick(event, obj) {
      console.log('event', event, 'obj', obj);
    },
    confirmAppointment() {
      const form = new FormData();
      form.append('schedule_date', this.form.date_value);
      form.append('schedule_started_at', this.form.slot_value);
      form.append('workplace_id', this.form.workplace_id);
      form.append('doctor_id', this.doctor.id);
      form.append('schedule_end_at', '');
      form.append('type', 'appointment_booking_request_sent');
      form.append('appointment_status', 'booked');
      form.append('service_type', 'visit');
      form.append('appointment_type', 'booked_appointment');
      form.append('patient_id', this.form.patient_id);
      form.append('payable_amount', this.doctor.doctor_information.service_fee.visit_fee);
      form.append('api_version', 'v2');
      axios
        .post(`${process.env.VUE_APP_URL}/api/v2/appointment/book`, form)
        .then((res) => {
          if (res.data.status_code == 200) {
            this.form = {};
            this.$notify({
              title: 'Success',
              message: 'Successfully Appointment created',
              type: 'success',
              duration: 2000,
            });
          } else {
            this.$notify({
              title: 'Failed',
              message: 'Appointment Failed',
              type: 'danger',
              duration: 2000,
            });
          }
        });
    },
  },
};
</script>

<style scoped>
.demo-tabs > .el-tabs__content {
  padding: 32px;
  color: #6b778c;
  font-size: 32px;
  font-weight: 600;
}

.el-date-editor {
  width: 100% !important;
}

.width-100 {
  width: 100%;
}

h3, h4, p {
  padding: 0;
  margin: 0;
}

.bg-gray {
  background-color: #ddd;
  align-items: center;
  justify-content: center;
  display: flex;
  text-align: center;
  padding: 0px 30px;
}

.demo-tabs > .el-tabs__content {
  padding: 32px;
  color: #6b778c;
  font-size: 32px;
  font-weight: 600;
}
</style>
<style>
.app_date_picker {
  width: 100% !important;
}
</style>
